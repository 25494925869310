import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Image from "../components/image";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="mdx-content">
      <h1>{`Kontakt`}</h1>
      <p className="font-semibold text-2xl">Masz pytania lub sugestie?<br />
Chcesz złożyć większe zamówienie?</p> 
      <p className="font-semibold text-2xl">
Napisz do nas na <br />
        <a href="mailto:kontakt@devstyle.pl" className="whitespace-nowrap"><Image alt="" src="content/mail.svg" className="inline transform -translate-y-1 pt-0.5" mdxType="Image" /> kontakt@devstyle.pl</a>!
      </p>
      <Image className="py-6 lg:py-10 mx-auto max-w-md" alt="" src="content/kontakt.svg" mdxType="Image" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      